.button {
  @extend %flex-ac-jc;
  gap: 8px;
  padding: 4px 16px;
  min-height: 44px;

  border-radius: 8px;
  @extend %transition--100;

  & svg {
    flex-shrink: 0;
  }
}

.button--solid-black {
  color: #fff;
  background-color: var(--color--dark, #0e0e12);
}
.button--solid-black-border-white {
  @extend .button--solid-black;
  border: 1px solid currentColor;
}
.button--solid-black-border-accent {
  @extend .button--solid-black;
  border: 1px solid var(--color--accent, #9cfe01);

  &:hover:not([disabled]) {
    color: #130f10;
    background-color: #05d604;
    border-color: #05d604;
  }

  &:active {
    color: #fff;
  }
}

.button--solid-dark {
  color: #fff;
  background: var(--Card-color, #27272a);
}

.button--solid-accent {
  color: #130f10;
  background-color: var(--color--accent, #9cfe01);

  &:hover:not([disabled]) {
    background-color: #05d604;
  }

  &:active {
    color: #fff;
  }
}

.button--accent {
  color: var(--color--accent, #9cfe01);
  background-color: transparent;
  // border-color: var(--color--accent, #9cfe01);

  &:hover:not([disabled]) {
    color: #130f10;
    background-color: #05d604;
  }

  &:active {
    color: #fff;
  }
}
