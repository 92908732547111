.backdrop.thumbnail__backdrop {
  // align-items: center;
  overflow-x: hidden;

  &.isHidden {
    display: none;
  }
}

.thumbnail__box {
  position: relative;

  &::before {
    content: '';

    position: absolute;
    display: block;
    width: 320px;
    height: 200px;

    background-image: url('https://crazzzyhall.com/images/icons/kangaroo2.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 240px;

    &::before {
      bottom: 0;
    }
  }

  @media screen and (min-width: 768px) {
    &::before {
      top: 160px;
      left: 350px;

      width: 640px;
      height: 440px;
    }
  }

  @media screen and (min-width: 1440px) {
    padding-right: 800px;

    &::before {
      top: 60px;
      left: initial;
      right: 0;
      width: 800px;
      height: 560px;
    }
  }
}

.thumbnail__subtitle {
  display: block;
  margin-bottom: 20px;

  color: #fff;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
}

.thumbnail__subtitle--accent {
  margin-top: 12px;

  color: #9cfe01;
  font-weight: 600;
}

.thumbnail__strong {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 24px 40px;
  margin: 64px 0;
  width: fit-content;

  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  text-transform: uppercase;

  border-radius: 60px;
  border: 4px solid #9cfe01;

  &::before {
    content: '';

    flex: 0 0 22px;
    width: 22px;
    height: 22px;

    background-color: #9cfe01;
    border-radius: 50%;
  }

  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 20px;
  }
}

.thumbnail__text {
  margin-bottom: 40px;
  color: #fff;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;

  & a {
    color: #9cfe01;

    &:hover {
      color: #05d604;
    }
    &:active {
      color: #fff;
    }
  }

  @media screen and (max-width: 767px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.thumbnail__link {
  @extend .thumbnail__text;

  &:hover {
    color: #05d604;
  }
  &:active {
    color: #fff;
  }
}
