.contacts__list {
  @extend %flex-dc;
  gap: 24px;
}

.contact__link {
  display: flex;
  gap: 16px;
  max-width: 244px;

  color: #CFD3D7;
  @extend %transition--100;

  & svg {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: var(--color--accent, #9cfe01);
  }
}