.page-title__wrap {
  @extend %relative;

  @extend %flex-ac-jc;
  gap: 8px;

  &::before,
  &::after {
    content: '';

    height: 1px;
    width: 100%;
    background-color: var( --color--accent, #9CFE01);
  }

  &::before {
    margin-bottom: auto;
    animation: slideFromRight 3s;
  }
  &::after {
    margin-top: auto;
    animation: slideFromLeft 3s;
  }

  @include breakpoint-min($tablet) {
    gap: 20px;
  }
  @include breakpoint-min($desktopL) {
    gap: 32px;
  }
}

.page-title {
  flex-shrink: 0;

  color:var( --color--accent, #9CFE01);
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px; /* 114.286% */
  animation: fadein 6s;

  @include breakpoint-max(360px - 1px) {
    flex-shrink: 1;
  }

  @include breakpoint-min($tablet) {
    font-size: 36px;
    line-height: 40px;
  }
  @include breakpoint-min($desktopL) {
    font-size: 52px;
    line-height: 60px;
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
