.subscribe-form {
  --gap: 24px;
  @extend %cardSet;
  width: 100%;
}
.subscribe-form__title-wrap {
  flex: 0 0 100%;
}
.subscribe-form__title {
  color: var(--Green_pixel, #9cfe01);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.subscribe-form__input {
  @extend %cardSet__item;
  height: 52px;
  padding: 16px;

  color: var(--Black-Black-30, #77808b);

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  border-radius: 8px;
  border: 0.5px solid #6f7377;
  background-color: #313131;
  @extend %transition--100;

  &:hover,
  &:focus-within {
    border-color: var(--color--accent, #9cfe01);
    outline: none;
  }
  &:active {
    color: #fff;
  }
}
.subscribe-form__btn {
  @extend %cardSet__item;
  height: 52px;

  color: var(--base-black, #000);
  font-size: 14px;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

@include breakpoint-min($tablet) {
  .subscribe-form {
    --gap: 16px;
    row-gap: 28px;
  }

  .subscribe-form__input,
  .subscribe-form__btn {
    --items: 3;
  }
}
@include breakpoint-min($desktopL) {
  .subscribe-form__input,
  .subscribe-form__btn {
    --items: 1;
  }
}
