.nav {
  width: 100%;
}

.nav__list {
  .header__nav & {
    @extend %flex-ac-jsb;
    gap: 16px;
  }

  .burger-menu__nav & {
    @extend %flex-dc;
    gap: 36px;
  }
}

.nav__link {
  color: var(--color--white, #fff);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */

  @extend %transition--100;

  .header__nav &,
  .burger-menu__nav & {
    text-transform: uppercase;

    &.active {
      color: var(--color--accent, #9cfe01);
    }
  }

  &:hover { color: var(--color--accent, #9cfe01); }
}