@mixin breakpoint-min($bp) {
  @media (min-width: $bp) {
    @content;
  }
}

@mixin breakpoint-max($bp) {
  @media (max-width: $bp) {
    @content;
  }
}

@mixin breakpoint-min-max($bp1, $bp2) {
  @media (min-width: $bp1) and (max-width: $bp2) {
    @content;
  }
}

@mixin breakpoint-min-width-height($bp1, $bp2) {
  @media (min-width: $bp1) and (min-height: $bp2) {
    @content;
  }
}

@mixin ratio-2() {
  @media (min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    @content;
  }
}
@mixin ratio-3() {
  @media (min-device-pixel-ratio: 3), (-webkit-min-device-pixel-ratio: 3),
    (min-resolution: 288dpi),
    (min-resolution: 3dppx) {
    @content;
  }
}

// ==================================================
// @include breakpoint-min($mobile) {aaa}
// @include breakpoint-min($tablet) {aaa}
// @include breakpoint-min($desktop) {aaa}

// @include breakpoint-max($extraSmallMax) {aaa}
// @include breakpoint-max($mobileMax) {aaa}
// @include breakpoint-max($tabletMax) {aaa}

// @include breakpoint-min-max($tablet, $tabletMax) {aaa}
