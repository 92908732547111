.visually-hidden {
  @extend %absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  @extend %overflow-h;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}
