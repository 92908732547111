@import '/src/common/scss/reset.scss';
@import '/src/common/scss/var.scss';
@import '/src/common/scss/utils.scss';
@import '/src/components/thumbnail/thumbnail.scss';
@import '/src/components/notification/notification.scss';
@import '/src/components/loader/loader.scss';

html {
  scroll-behavior: smooth;
  scroll-padding-top: 60px;

  @include breakpoint-min($tablet) {
    scroll-padding-top: 72px;
  }
  @include breakpoint-min($desktopL) {
    scroll-padding-top: 128px;
  }
}
body {
  @extend %relative;

  @extend %flex-dc;
  min-height: 100vh;

  // font-family: 'Barlow', sans-serif;
  // font-size: 16px;
  color: #fff;

  background-color: var(--color--dark, #0e0e12);
  @extend %transition--200;
  overflow-y: scroll;

  &.freeze,
  &.isLoading,
  &.modalIsOpen,
  &.burgerMenuIsOpen {
    @extend %overflow-h;
  }
}
main {
  @extend %relative;
  flex-grow: 1;
  @extend %flex-dc;
  padding-top: 60px;

  @extend %transition--200;
  // @extend %overflow-h;
  contain: paint;

  @include breakpoint-min($tablet) {
    padding-top: 72px;
  }
  @include breakpoint-min($desktopL) {
    padding-top: 128px;
  }
}
footer {
  margin-top: auto;
}

svg {
  pointer-events: none;
}
button,
a {
  @extend %pointer;
}

.section {
  padding: 60px 0;

  @include breakpoint-min($tablet) {
    padding: 80px 0;
  }
  @include breakpoint-min($desktopL) {
    padding: 100px 0;
  }
}

.container {
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;

  @include breakpoint-min($mobileSM) {
    max-width: $mobileSM;
    padding: 0 20px;
  }
  @include breakpoint-min($tablet) {
    max-width: $tablet;
    padding: 0 32px;
  }
  @include breakpoint-min($desktopL) {
    max-width: $desktopL;
    padding: 0 112px;
  }
}
.backdrop {
  @extend %fixed-tlrb;
  @extend %flex-ac-jc;
  align-items: flex-start;
  padding: 16px;

  background-color: var(--color--dark, #0e0e12);
  z-index: 3000;
  overflow: scroll;

  @media screen and (min-width: 1024px) and (orientation: landscape) and (min-height: 1024px) {
    align-items: center;
  }
}

.list {
  list-style: none;
}
.link {
  text-decoration: none;
  color: inherit;
}
.swiper {
  overflow: hidden;
}
.mb-16 {
  margin-bottom: 16px
}


@import '/src/components/logo/logo.scss';
@import '/src/components/nav/nav.scss';
@import '/src/components/button/button.scss';
@import '/src/components/btn-up/btn-up.scss';
@import '/src/components/link/link.scss';
@import '/src/components/page-title/page-title.scss';
@import '/src/components/contacts/contacts.scss';
@import '/src/components/social-networks/social-networks.scss';
@import '/src/components/burger-menu/burger-menu.scss';
@import '/src/components/header/header.scss';
@import '/src/components/footer/footer.scss';
