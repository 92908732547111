.social-networks__list {
  @extend %flex-ac;
  gap: 20px;
}

.social-networks__link {
  @extend %flex-ac-jc;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  padding: 5px;

  color: #fff;

  border-radius: 2px;
  border: 0.5px solid currentColor;
  background-color: transparent;
  @extend %transition--100;

  &:hover {
    color: var(--color--accent, #9cfe01);
  }

  &:active {
    color: #05d604;
  }
}

.social-networks__icons {
  stroke: currentColor;
}
