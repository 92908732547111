@import '/src/components/burger-menu/burger-menu-btn.scss';

.burger-menu {
  @extend %fixed;
  top: 60px;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  background-color: var(--color--dark, #0e0e12);
  @extend %transition--200;
  z-index: 20;

  &.isHidden {
    right: -101%;

    button,
    a {
      pointer-events: none;
      visibility: hidden;
    }
  }

  @include breakpoint-min($tablet) {
    top: 72px;
    width: 418px;
  }

  @include breakpoint-min($desktopL) {
    display: none;
  }
}
.burger-menu__container {
  @extend %flex-dc;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  @include breakpoint-min($tablet) {
    padding: 60px 52px;
  }
}
.burger-menu__footer {
  margin-top: auto;
}

.burger-menu__backdrop {
  @extend %fixed;
  top: 72px;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  background-color: var(--Card-color, #27272A);
  @extend %transition--200;
  opacity: 0.8;

  &.isHidden {
    @extend %pvo;
  }
}
