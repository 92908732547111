.notification {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: scroll;

  transition: 400ms linear;
  z-index: 3;

  &.isHidden {
    top: -101%;

    button,
    a {
      pointer-events: none;
      visibility: hidden;
    }
  }

  @media (max-height: 600px) and (orientation: landscape) {
    &.isHidden {
      top: -201%;
    }
  }
  @media (max-height: 300px) and (orientation: landscape) {
    &.isHidden {
      top: -301%;
    }
  }
  @media (max-height: 600px) and (orientation: portrait) {
    &.isHidden {
      top: -301%;
    }
  }
}

.notification__backdrop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  background-color: #27272a;
  transition: 200ms linear;
  opacity: 0.8;
  z-index: -1;

  .isHidden & {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
  }
}

.notification__container {
  position: relative;
  z-index: 1;

  width: 100%;
  padding: 16px 16px 0;
  margin: 0 auto;

  @media screen and (min-width: 360px) {
    max-width: 360px;
    padding: 20px 20px 0;
  }
  @media screen and (min-width: 768px) {
    max-width: 572px;
    padding: 100px 0 0;
  }

  // @include breakpoint-min($mobileSM) {
  //   max-width: $mobileSM;
  //   padding: 20px 20px 0;
  // }

  // @include breakpoint-min($tablet) {
  //   max-width: 572px;
  //   padding: 100px 0 0;
  // }
}
.notification__btn-close {
  position: absolute;
  top: 20px;
  right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  color: #000;
  background-color: transparent;

  & svg {
    fill: currentColor;
  }

  // @include breakpoint-min($mobileSM) {
  //   top: 24px;
  //   right: 24px;
  // }

  // @include breakpoint-min($tablet) {
  //   top: 100px;
  //   right: -44px;

  //   width: 24px;
  //   height: 24px;

  //   color: var(--color--accent, #9cfe01);
  // }

  @media screen and (min-width: 360px) {
    top: 24px;
    right: 24px;
  }
  @media screen and (min-width: 768px) {
    top: 100px;
    right: -44px;

    width: 24px;
    height: 24px;

    color: #9cfe01;
  }
}

.notification__content {
  z-index: 1;

  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  margin: 0 auto;

  color: #000;

  border-radius: 8px;
  background-color: #fff;

  opacity: 1;

  @media screen and (min-width: 768px) {
    max-width: 572px;
    padding: 28px;
  }
}
.notification__body {
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding-bottom: 12px;
}

.notification__title {
  margin-bottom: 24px;

  color: #27272a;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.notification__text {
  color: #27272a;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.notification__text--600 {
  font-weight: 600;
}

.notification__link {
  color: #208102;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.notification__link--2 {
  color: #0e0e12;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: underline;
}

.notification__footer {
  padding-top: 12px;

  border-top: 1px solid #8d8d8d;
}
.notification__btn {
  width: 108px;
  height: 48px;
  margin: 0 auto;

  color: #0e0e12;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
