.btn-up__wrap {
  position: sticky;
  left: 0;
  bottom: 0;
  height: 0;
  z-index: 2;

  background-color: transparent;
  pointer-events: none;
}

.btn-up {
  @extend %absolute;
  bottom: 20px;
  right: 20px;

  @extend %flex-ac-jc;
  width: 40px;
  height: 40px;
  margin-top: auto;

  color: #50555a;

  background-color: var(--color--accent, #9cfe01);
  @extend %transition--100;
  @extend %border-radius-50;
  pointer-events: auto;

  & svg {
    width: 24px;
    height: 24px;

    fill: currentColor;
    transform: scale(1.05) rotate(180deg);
  }

  &.isHidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  &:hover,
  &:active {
    filter: drop-shadow(-1px -1px 10px rgba(156, 254, 1, 0.3));
  }

  @include breakpoint-min($tablet) {
    width: 44px;
    height: 44px;

    bottom: 32px;
    right: 32px;
  }

  @include breakpoint-min($desktopL) {
    bottom: 36px;
    right: 36px;
  }
}
