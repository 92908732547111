@import '/src/components/forms/subscribe-form/subscribe-form.scss';

footer {
  margin-top: auto;

  color: #cfd3d7;
  background-color: #080808;
}
.footer__top {
  padding: 44px 0;

  @include breakpoint-min($desktopL) {
    padding: 48px 0;
  }
}
.footer__bottom {
  padding: 24px 0 32px;
  border-top: 0.5px solid #464749;

  @include breakpoint-min($tablet) {
    padding: 40px 0;
  }
}

.footer__box-wrap {
  @extend %relative;
  @extend %flex-ww;
  --gap: 72px;
  gap: var(--gap);
  row-gap: 32px;

  @include breakpoint-max($tablet - 1) {
    padding-top: 68px;
  }
  @include breakpoint-min($tablet) {
    justify-content: space-between;
  }
  @include breakpoint-min($desktopL) {
    --gap: 60px;
  }
}
.footer__box--logo {
  @extend %flex-dc;
  gap: 32px;

  @include breakpoint-max($tablet - 1) {
    width: 100%;
    padding-bottom: 32px;
    border-bottom: 1px solid #464749;

    & .social-networks__list {
      justify-content: center;
    }
  }

  @include breakpoint-min($desktopL) {
    flex: 0 0 280px;
    max-width: 280px;
  }
}
.footer__box--nav {
  @extend %flex-dc;
  gap: 32px;

  @include breakpoint-max($tablet - 1) {
    width: 100%;
    order: -1;
  }

  @include breakpoint-min($tablet) {
    flex-direction: row;
    gap: var(--gap);
  }
}
.footer__box--forms {
  @include breakpoint-min-max($tablet, $desktopL - 1) {
    --items: 1;
  }
  @include breakpoint-min($desktopL) {
    flex: 0 0 280px;
    max-width: 280px;
  }
}

.footer__logo {
  & svg {
    width: 158px;
    height: 25px;
  }

  @include breakpoint-max($tablet - 1) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.footer-nav__wrap {
  @extend %flex-dc;
  gap: 32px;

  border-bottom: 1px solid #464749;

  @include breakpoint-min($tablet) {
    border: none;
  }
}
.footer-nav__title {
  @extend %flex-ac-jsb;

  color: var(--color--accent, #9cfe01);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */

  & svg {
    stroke: #cfd3d7;
    @extend %transition--300;
  }

  &.active svg {
    stroke: var(--color--accent, #9cfe01);
    transform: rotate(180deg);
  }

  @include breakpoint-min($tablet) {
    & svg {
      display: none;
    }
    pointer-events: none;
  }
}
.footer-nav {
  @extend %flex-dc;
  gap: 16px;
  max-height: 100%;
  overflow: hidden;

  @extend %transition--300;
  [data-accordion-box] &:not(.active) { max-height: 0px; padding: 0; }

  @include breakpoint-max($tablet - 1) {
    padding-bottom: 32px;
  }
  @include breakpoint-min($desktopL) {
    flex-direction: row;
    gap: var(--gap);
  }
}
.footer-nav__list {
  .footer__top & {
    @extend %flex-dc;
    gap: 16px;
  }
  .footer__bottom & {
    @extend %flex-ac-jc;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 24px;
  }

  @include breakpoint-min($tablet) {
    .footer__bottom & {
      gap: 32px;
    }
  }
  @include breakpoint-min($desktopL) {
    .footer__bottom & {
      justify-content: flex-start;
    }
  }
}
.nav__item--policy {
  @include breakpoint-max($tablet - 1) {
    order: -1;
  }
}
.footer-nav .nav__link {
  color: var(--Text-footer, #cfd3d7);
  font-weight: 400;

  &:hover { color: var(--color--accent, #9cfe01); }
}

.copyright {
  color: var(--Text-footer, #cfd3d7);
  line-height: 24px; /* 150% */
}
