*, :before, :after {
  box-sizing: border-box;
}

* {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  scroll-behavior: smooth;
  text-rendering: optimizespeed;
  line-height: normal;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img, picture, video, canvas, svg, iframe {
  max-width: 100%;
  display: block;
}

img {
  vertical-align: top;
}

ol, ul, li {
  list-style: none;
}

a, input, button, textarea, select {
  font: inherit;
}

button[disabled] {
  cursor: auto;
}

input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: #0000;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  font-size: inherit;
  font-weight: inherit;
}

a {
  text-decoration: none;
}

:root {
  --color--white: #fff;
  --color--dark: #0e0e12;
  --color--accent: #9cfe01;
  --color--grey: #8d8d8d;
  --transition--20: 20ms linear;
  --transition--100: .1s linear;
  --transition--200: .2s linear;
  --transition--300: .3s linear;
  --transition--400: .4s linear;
  --transition--500: .5s linear;
  --items: 1;
  --gap: 16px;
  --index: calc(1vh + 1vw);
  --border-radius: 4px;
  --text-indent: 40px;
}

.header__contact-link, .header__btn-wrap, .header__bottom, .header__top, .social-networks__list, .footer-nav__title, .header__container, .header__nav .nav__list, .footer__bottom .footer-nav__list, .burger-menu__btn, .social-networks__link, .page-title__wrap, .btn-up, .button, .link, .backdrop {
  align-items: center;
  display: flex;
}

.footer__bottom .footer-nav__list, .burger-menu__btn, .social-networks__link, .page-title__wrap, .btn-up, .button, .link, .backdrop {
  justify-content: center;
}

.footer-nav__title, .header__container, .header__nav .nav__list {
  justify-content: space-between;
}

.footer__top .footer-nav__list, .footer-nav, .footer-nav__wrap, .footer__box--nav, .footer__box--logo, .header, .burger-menu__container, .contacts__list, .burger-menu__nav .nav__list, main, body {
  flex-direction: column;
  display: flex;
}

.footer__box-wrap, .subscribe-form {
  flex-wrap: wrap;
  display: flex;
}

.footer__box-wrap, .burger-menu__btn, .page-title__wrap, main, body, .lds-roller {
  position: relative;
}

.burger-menu__btn .burger-line, .btn-up, .lds-roller div:after, .visually-hidden {
  position: absolute;
}

.burger-menu__backdrop, .burger-menu, .header, .backdrop {
  position: fixed;
}

.backdrop {
  inset: 0;
}

.header {
  top: 0;
  left: 0;
}

button, a {
  cursor: pointer;
}

.burger-menu__btn .burger-line {
  border-radius: var(--border-radius);
}

.btn-up, .lds-roller div:after {
  border-radius: 50%;
}

.header__logo svg, .thumbnail__logo svg {
  transition: var(--transition--20, 20ms linear);
}

.subscribe-form__input, .header__contact-link, .social-networks__link, .contact__link, .btn-up, .button, .link, .nav__link {
  transition: var(--transition--100, .1s linear);
}

.burger-menu__backdrop, .burger-menu, main, body {
  transition: var(--transition--200, .2s linear);
}

.footer-nav, .footer-nav__title svg {
  transition: var(--transition--300, .3s linear);
}

body.freeze, body.isLoading, body.modalIsOpen, body.burgerMenuIsOpen, .visually-hidden {
  overflow: hidden;
}

.burger-menu__backdrop.isHidden {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.subscribe-form {
  gap: var(--gap);
}

.subscribe-form__btn, .subscribe-form__input {
  flex-basis: calc((100% - var(--gap) * (var(--items)  - 1)) / var(--items));
  width: calc((100% - var(--gap) * (var(--items)  - 1)) / var(--items));
}

.visually-hidden {
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
}

.backdrop.thumbnail__backdrop {
  overflow-x: hidden;
}

.backdrop.thumbnail__backdrop.isHidden {
  display: none;
}

.thumbnail__box {
  position: relative;
}

.thumbnail__box:before {
  content: "";
  background-image: url("https://crazzzyhall.com/images/icons/kangaroo2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 320px;
  height: 200px;
  display: block;
  position: absolute;
}

@media screen and (width <= 767px) {
  .thumbnail__box {
    padding-bottom: 240px;
  }

  .thumbnail__box:before {
    bottom: 0;
  }
}

@media screen and (width >= 768px) {
  .thumbnail__box:before {
    width: 640px;
    height: 440px;
    top: 160px;
    left: 350px;
  }
}

@media screen and (width >= 1440px) {
  .thumbnail__box {
    padding-right: 800px;
  }

  .thumbnail__box:before {
    top: 60px;
    left: initial;
    width: 800px;
    height: 560px;
    right: 0;
  }
}

.thumbnail__subtitle {
  color: #fff;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  display: block;
}

.thumbnail__subtitle--accent {
  color: #9cfe01;
  margin-top: 12px;
  font-weight: 600;
}

.thumbnail__strong {
  color: #fff;
  text-transform: uppercase;
  border: 4px solid #9cfe01;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: fit-content;
  margin: 64px 0;
  padding: 24px 40px;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  position: relative;
}

.thumbnail__strong:before {
  content: "";
  background-color: #9cfe01;
  border-radius: 50%;
  flex: 0 0 22px;
  width: 22px;
  height: 22px;
}

@media screen and (width <= 767px) {
  .thumbnail__strong {
    font-size: 20px;
    line-height: 20px;
  }
}

.thumbnail__text, .thumbnail__link {
  color: #fff;
  margin-bottom: 40px;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}

.thumbnail__text a, .thumbnail__link a {
  color: #9cfe01;
}

.thumbnail__text a:hover, .thumbnail__link a:hover {
  color: #05d604;
}

.thumbnail__text a:active, .thumbnail__link a:active {
  color: #fff;
}

@media screen and (width <= 767px) {
  .thumbnail__text, .thumbnail__link {
    font-size: 20px;
    line-height: 24px;
  }
}

.thumbnail__link:hover {
  color: #05d604;
}

.thumbnail__link:active {
  color: #fff;
}

.notification {
  z-index: 3;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  transition: all .4s linear;
  position: fixed;
  top: 0;
  overflow: scroll;
}

.notification.isHidden {
  top: -101%;
}

.notification.isHidden button, .notification.isHidden a {
  pointer-events: none;
  visibility: hidden;
}

@media (height <= 600px) and (orientation: landscape) {
  .notification.isHidden {
    top: -201%;
  }
}

@media (height <= 300px) and (orientation: landscape) {
  .notification.isHidden {
    top: -301%;
  }
}

@media (height <= 600px) and (orientation: portrait) {
  .notification.isHidden {
    top: -301%;
  }
}

.notification__backdrop {
  opacity: .8;
  z-index: -1;
  background-color: #27272a;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  transition: all .2s linear;
  position: fixed;
  top: 0;
}

.isHidden .notification__backdrop {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
}

.notification__container {
  z-index: 1;
  width: 100%;
  margin: 0 auto;
  padding: 16px 16px 0;
  position: relative;
}

@media screen and (width >= 360px) {
  .notification__container {
    max-width: 360px;
    padding: 20px 20px 0;
  }
}

@media screen and (width >= 768px) {
  .notification__container {
    max-width: 572px;
    padding: 100px 0 0;
  }
}

.notification__btn-close {
  color: #000;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;
}

.notification__btn-close svg {
  fill: currentColor;
}

@media screen and (width >= 360px) {
  .notification__btn-close {
    top: 24px;
    right: 24px;
  }
}

@media screen and (width >= 768px) {
  .notification__btn-close {
    color: #9cfe01;
    width: 24px;
    height: 24px;
    top: 100px;
    right: -44px;
  }
}

.notification__content {
  z-index: 1;
  color: #000;
  opacity: 1;
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  display: flex;
}

@media screen and (width >= 768px) {
  .notification__content {
    max-width: 572px;
    padding: 28px;
  }
}

.notification__body {
  flex-direction: column;
  gap: 16px;
  padding-bottom: 12px;
  display: flex;
}

.notification__title {
  color: #27272a;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.notification__text {
  color: #27272a;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.notification__text--600 {
  font-weight: 600;
}

.notification__link {
  color: #208102;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.notification__link--2 {
  color: #0e0e12;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-decoration: underline;
}

.notification__footer {
  border-top: 1px solid #8d8d8d;
  padding-top: 12px;
}

.notification__btn {
  color: #0e0e12;
  width: 108px;
  height: 48px;
  margin: 0 auto;
  font-weight: 600;
  line-height: 24px;
}

.backdrop.loader__backdrop {
  align-items: center;
}

.isHidden .loader__backdrop {
  display: none;
}

.lds-roller {
  width: 80px;
  height: 80px;
  display: inline-block;
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  background: #f0f0f0;
  width: 7px;
  height: 7px;
  margin: -4px 0 0 -4px;
  display: block;
}

.lds-roller div:first-child {
  animation-delay: -.036s;
}

.lds-roller div:first-child:after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  background-color: var(--color--accent, #9cfe01);
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 60px;
}

@media (width >= 768px) {
  html {
    scroll-padding-top: 72px;
  }
}

@media (width >= 1440px) {
  html {
    scroll-padding-top: 128px;
  }
}

body {
  color: #fff;
  background-color: var(--color--dark, #0e0e12);
  min-height: 100vh;
  overflow-y: scroll;
}

main {
  contain: paint;
  flex-grow: 1;
  padding-top: 60px;
}

@media (width >= 768px) {
  main {
    padding-top: 72px;
  }
}

@media (width >= 1440px) {
  main {
    padding-top: 128px;
  }
}

footer {
  margin-top: auto;
}

svg {
  pointer-events: none;
}

.section {
  padding: 60px 0;
}

@media (width >= 768px) {
  .section {
    padding: 80px 0;
  }
}

@media (width >= 1440px) {
  .section {
    padding: 100px 0;
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
}

@media (width >= 360px) {
  .container {
    max-width: 360px;
    padding: 0 20px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
    padding: 0 32px;
  }
}

@media (width >= 1440px) {
  .container {
    max-width: 1440px;
    padding: 0 112px;
  }
}

.backdrop {
  background-color: var(--color--dark, #0e0e12);
  z-index: 3000;
  align-items: flex-start;
  padding: 16px;
  overflow: scroll;
}

@media screen and (width >= 1024px) and (orientation: landscape) and (height >= 1024px) {
  .backdrop {
    align-items: center;
  }
}

.list {
  list-style: none;
}

.link {
  color: inherit;
  text-decoration: none;
}

.swiper {
  overflow: hidden;
}

.mb-16 {
  margin-bottom: 16px;
}

.header__logo, .thumbnail__logo {
  gap: 1.2px;
  width: 122px;
  height: 20px;
  display: flex;
}

.header__logo svg, .thumbnail__logo svg {
  fill: #fff;
  height: auto;
}

.header__logo .logo__icon--1, .thumbnail__logo .logo__icon--1 {
  animation: .8s ease-in 2s forwards zzz, .8s ease-in 4.46s reverse forwards zzz;
}

.header__logo .logo__icon--2, .thumbnail__logo .logo__icon--2 {
  animation: .8s ease-in 2.82s forwards zzz, .8s ease-in 4.46s reverse forwards zzz;
}

.header__logo .logo__icon--3, .thumbnail__logo .logo__icon--3 {
  animation: .8s ease-in 3.64s forwards zzz, .8s ease-in 4.46s reverse forwards zzz;
}

.header__logo .logo__icon--4, .thumbnail__logo .logo__icon--4 {
  transform: translateY(10%);
}

@media (width <= 359px) {
  .header__logo, .thumbnail__logo {
    width: 104px;
    height: 17px;
  }
}

@keyframes zzz {
  0% {
    transform: rotateY(0);
  }

  100% {
    transform: rotateY(-180deg);
  }
}

.thumbnail__logo {
  gap: 1.4px;
  width: 100%;
  height: auto;
}

@media (width >= 768px) {
  .thumbnail__logo {
    gap: 1.8px;
    width: 564px;
    height: 92px;
  }
}

.nav {
  width: 100%;
}

.header__nav .nav__list {
  gap: 16px;
}

.burger-menu__nav .nav__list {
  gap: 36px;
}

.nav__link {
  color: var(--color--white, #fff);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.header__nav .nav__link, .burger-menu__nav .nav__link {
  text-transform: uppercase;
}

.header__nav .nav__link.active, .burger-menu__nav .nav__link.active, .nav__link:hover {
  color: var(--color--accent, #9cfe01);
}

.button, .link {
  border-radius: 8px;
  gap: 8px;
  min-height: 44px;
  padding: 4px 16px;
}

.button svg, .link svg {
  flex-shrink: 0;
}

.button--solid-black, .link--solid-black, .button--solid-black-border-accent, .link--solid-black-border-accent, .button--solid-black-border-white, .link--solid-black-border-white {
  color: #fff;
  background-color: var(--color--dark, #0e0e12);
}

.button--solid-black-border-white, .link--solid-black-border-white {
  border: 1px solid;
}

.button--solid-black-border-accent, .link--solid-black-border-accent {
  border: 1px solid var(--color--accent, #9cfe01);
}

.button--solid-black-border-accent:hover:not([disabled]), .link--solid-black-border-accent:hover:not([disabled]) {
  color: #130f10;
  background-color: #05d604;
  border-color: #05d604;
}

.button--solid-black-border-accent:active, .link--solid-black-border-accent:active {
  color: #fff;
}

.button--solid-dark, .link--solid-dark {
  color: #fff;
  background: var(--Card-color, #27272a);
}

.button--solid-accent, .link--solid-accent {
  color: #130f10;
  background-color: var(--color--accent, #9cfe01);
}

.button--solid-accent:hover:not([disabled]), .link--solid-accent:hover:not([disabled]) {
  background-color: #05d604;
}

.button--solid-accent:active, .link--solid-accent:active {
  color: #fff;
}

.button--accent {
  color: var(--color--accent, #9cfe01);
  background-color: #0000;
}

.button--accent:hover:not([disabled]) {
  color: #130f10;
  background-color: #05d604;
}

.button--accent:active {
  color: #fff;
}

.btn-up__wrap {
  z-index: 2;
  pointer-events: none;
  background-color: #0000;
  height: 0;
  position: sticky;
  bottom: 0;
  left: 0;
}

.btn-up {
  color: #50555a;
  background-color: var(--color--accent, #9cfe01);
  pointer-events: auto;
  width: 40px;
  height: 40px;
  margin-top: auto;
  bottom: 20px;
  right: 20px;
}

.btn-up svg {
  fill: currentColor;
  width: 24px;
  height: 24px;
  transform: scale(1.05)rotate(180deg);
}

.btn-up.isHidden {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.btn-up:hover, .btn-up:active {
  filter: drop-shadow(-1px -1px 10px #9cfe014d);
}

@media (width >= 768px) {
  .btn-up {
    width: 44px;
    height: 44px;
    bottom: 32px;
    right: 32px;
  }
}

@media (width >= 1440px) {
  .btn-up {
    bottom: 36px;
    right: 36px;
  }
}

.page-title__wrap {
  gap: 8px;
}

.page-title__wrap:before, .page-title__wrap:after {
  content: "";
  background-color: var(--color--accent, #9cfe01);
  width: 100%;
  height: 1px;
}

.page-title__wrap:before {
  margin-bottom: auto;
  animation: 3s slideFromRight;
}

.page-title__wrap:after {
  margin-top: auto;
  animation: 3s slideFromLeft;
}

@media (width >= 768px) {
  .page-title__wrap {
    gap: 20px;
  }
}

@media (width >= 1440px) {
  .page-title__wrap {
    gap: 32px;
  }
}

.page-title {
  color: var(--color--accent, #9cfe01);
  text-align: center;
  flex-shrink: 0;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  animation: 6s fadein;
}

@media (width <= 359px) {
  .page-title {
    flex-shrink: 1;
  }
}

@media (width >= 768px) {
  .page-title {
    font-size: 36px;
    line-height: 40px;
  }
}

@media (width >= 1440px) {
  .page-title {
    font-size: 52px;
    line-height: 60px;
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.contacts__list {
  gap: 24px;
}

.contact__link {
  color: #cfd3d7;
  gap: 16px;
  max-width: 244px;
  display: flex;
}

.contact__link svg {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
}

.contact__link:hover {
  color: var(--color--accent, #9cfe01);
}

.social-networks__list {
  gap: 20px;
}

.social-networks__link {
  color: #fff;
  background-color: #0000;
  border: .5px solid;
  border-radius: 2px;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  padding: 5px;
}

.social-networks__link:hover {
  color: var(--color--accent, #9cfe01);
}

.social-networks__link:active {
  color: #05d604;
}

.social-networks__icons {
  stroke: currentColor;
}

.burger-menu__btn {
  color: var(--color--accent, #9cfe01);
  background-color: var(--color--dark, #0e0e12);
  width: 28px;
  height: 28px;
}

body.burgerMenuIsOpen .burger-menu__btn {
  z-index: 20;
}

@media (width >= 768px) {
  .burger-menu__btn {
    width: 44px;
    height: 44px;
  }
}

@media (width >= 1440px) {
  .burger-menu__btn {
    display: none;
  }
}

.burger-menu__btn .burger-line {
  pointer-events: none;
  background-color: currentColor;
  width: 18px;
  height: 2px;
  margin: 0;
  transition-property: height, opacity, transform;
  transition-duration: .4s;
}

.burger-line:first-child {
  transform: translateY(6px);
}

.burger-line:last-child {
  transform: translateY(-6px);
}

body.burgerMenuIsOpen .burger-menu__btn .burger-line:first-child {
  transform: rotate(-45deg);
}

body.burgerMenuIsOpen .burger-menu__btn .burger-line:nth-child(2n) {
  opacity: 0;
}

body.burgerMenuIsOpen .burger-menu__btn .burger-line:last-child {
  transform: rotate(45deg);
}

.burger-menu {
  background-color: var(--color--dark, #0e0e12);
  z-index: 20;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  top: 60px;
  right: 0;
}

.burger-menu.isHidden {
  right: -101%;
}

.burger-menu.isHidden button, .burger-menu.isHidden a {
  pointer-events: none;
  visibility: hidden;
}

@media (width >= 768px) {
  .burger-menu {
    width: 418px;
    top: 72px;
  }
}

@media (width >= 1440px) {
  .burger-menu {
    display: none;
  }
}

.burger-menu__container {
  width: 100%;
  height: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (width >= 768px) {
  .burger-menu__container {
    padding: 60px 52px;
  }
}

.burger-menu__footer {
  margin-top: auto;
}

.burger-menu__backdrop {
  background-color: var(--Card-color, #27272a);
  opacity: .8;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  top: 72px;
  right: 0;
}

.header {
  background-color: var(--color--dark, #0e0e12);
  z-index: 3;
  width: 100%;
}

.header__top {
  border-bottom: 1px solid #464749;
  height: 60px;
}

@media (width >= 768px) {
  .header__top {
    height: 72px;
  }
}

.header__bottom {
  height: 56px;
}

@media (width <= 1439px) {
  .header__bottom {
    display: none;
  }
}

.header__btn-wrap {
  gap: 24px;
}

@media (width <= 767px) {
  .header__btn-wrap {
    gap: 16px;
  }
}

.header__btn {
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

@media (width <= 767px) {
  .header__btn {
    display: none;
  }
}

.header__contact-link {
  color: #fff;
  gap: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.header__contact-link:hover {
  color: var(--color--accent, #9cfe01);
}

@media (width >= 1440px) {
  .header__contact-link {
    font-size: 16px;
    line-height: 24px;
  }
}

.subscribe-form {
  --gap: 24px;
  width: 100%;
}

.subscribe-form__title-wrap {
  flex: 0 0 100%;
}

.subscribe-form__title {
  color: var(--Green_pixel, #9cfe01);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.subscribe-form__input {
  color: var(--Black-Black-30, #77808b);
  background-color: #313131;
  border: .5px solid #6f7377;
  border-radius: 8px;
  height: 52px;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.subscribe-form__input:hover, .subscribe-form__input:focus-within {
  border-color: var(--color--accent, #9cfe01);
  outline: none;
}

.subscribe-form__input:active {
  color: #fff;
}

.subscribe-form__btn {
  color: var(--base-black, #000);
  height: 52px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

@media (width >= 768px) {
  .subscribe-form {
    --gap: 16px;
    row-gap: 28px;
  }

  .subscribe-form__input, .subscribe-form__btn {
    --items: 3;
  }
}

@media (width >= 1440px) {
  .subscribe-form__input, .subscribe-form__btn {
    --items: 1;
  }
}

footer {
  color: #cfd3d7;
  background-color: #080808;
  margin-top: auto;
}

.footer__top {
  padding: 44px 0;
}

@media (width >= 1440px) {
  .footer__top {
    padding: 48px 0;
  }
}

.footer__bottom {
  border-top: .5px solid #464749;
  padding: 24px 0 32px;
}

@media (width >= 768px) {
  .footer__bottom {
    padding: 40px 0;
  }
}

.footer__box-wrap {
  --gap: 72px;
  gap: var(--gap);
  row-gap: 32px;
}

@media (width <= 767px) {
  .footer__box-wrap {
    padding-top: 68px;
  }
}

@media (width >= 768px) {
  .footer__box-wrap {
    justify-content: space-between;
  }
}

@media (width >= 1440px) {
  .footer__box-wrap {
    --gap: 60px;
  }
}

.footer__box--logo {
  gap: 32px;
}

@media (width <= 767px) {
  .footer__box--logo {
    border-bottom: 1px solid #464749;
    width: 100%;
    padding-bottom: 32px;
  }

  .footer__box--logo .social-networks__list {
    justify-content: center;
  }
}

@media (width >= 1440px) {
  .footer__box--logo {
    flex: 0 0 280px;
    max-width: 280px;
  }
}

.footer__box--nav {
  gap: 32px;
}

@media (width <= 767px) {
  .footer__box--nav {
    order: -1;
    width: 100%;
  }
}

@media (width >= 768px) {
  .footer__box--nav {
    gap: var(--gap);
    flex-direction: row;
  }
}

@media (width >= 768px) and (width <= 1439px) {
  .footer__box--forms {
    --items: 1;
  }
}

@media (width >= 1440px) {
  .footer__box--forms {
    flex: 0 0 280px;
    max-width: 280px;
  }
}

.footer__logo svg {
  width: 158px;
  height: 25px;
}

@media (width <= 767px) {
  .footer__logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.footer-nav__wrap {
  border-bottom: 1px solid #464749;
  gap: 32px;
}

@media (width >= 768px) {
  .footer-nav__wrap {
    border: none;
  }
}

.footer-nav__title {
  color: var(--color--accent, #9cfe01);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.footer-nav__title svg {
  stroke: #cfd3d7;
}

.footer-nav__title.active svg {
  stroke: var(--color--accent, #9cfe01);
  transform: rotate(180deg);
}

@media (width >= 768px) {
  .footer-nav__title {
    pointer-events: none;
  }

  .footer-nav__title svg {
    display: none;
  }
}

.footer-nav {
  gap: 16px;
  max-height: 100%;
  overflow: hidden;
}

[data-accordion-box] .footer-nav:not(.active) {
  max-height: 0;
  padding: 0;
}

@media (width <= 767px) {
  .footer-nav {
    padding-bottom: 32px;
  }
}

@media (width >= 1440px) {
  .footer-nav {
    gap: var(--gap);
    flex-direction: row;
  }
}

.footer__top .footer-nav__list {
  gap: 16px;
}

.footer__bottom .footer-nav__list {
  flex-wrap: wrap;
  gap: 8px 24px;
}

@media (width >= 768px) {
  .footer__bottom .footer-nav__list {
    gap: 32px;
  }
}

@media (width >= 1440px) {
  .footer__bottom .footer-nav__list {
    justify-content: flex-start;
  }
}

@media (width <= 767px) {
  .nav__item--policy {
    order: -1;
  }
}

.footer-nav .nav__link {
  color: var(--Text-footer, #cfd3d7);
  font-weight: 400;
}

.footer-nav .nav__link:hover {
  color: var(--color--accent, #9cfe01);
}

.copyright {
  color: var(--Text-footer, #cfd3d7);
  line-height: 24px;
}
/*# sourceMappingURL=index.536d2f90.css.map */
