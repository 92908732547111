:root {
  // ===== colors =====
  --color--white: #fff;
  --color--dark: #0e0e12;
  --color--accent: #9cfe01;
  --color--grey: #8d8d8d;
  // #464749;
  // background-color: var(--color--white, #fff);
  // background-color: var(--color--dark, #0e0e12);
  // background-color: var( --color--accent, #9CFE01);
  // background-color: var( --color--grey, #8d8d8d);
  // ===== transition =====
  --transition--20: 20ms linear;
  --transition--100: 100ms linear;
  --transition--200: 200ms linear;
  --transition--300: 300ms linear;
  --transition--400: 400ms linear;
  --transition--500: 500ms linear;
  // ===== decor =====
  // ===== other =====
  --items: 1;
  --gap: 16px;
  --index: calc(1vh + 1vw);
  --border-radius: 4px;
  --text-indent: 40px;
}

// ===== breakpoints =====
$mobileS: 320px;
$mobileSM: 360px;
$mobileM: 375px;
$mobileL: 425px;
$mobile: 480px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;
$desktopL: 1440px;
$desktopXL: 1920px;
// ===== breakpoints =====
