.header {
  @extend %fixed-tl;

  @extend %flex-dc;
  width: 100%;

  background-color: var(--color--dark, #0e0e12);
  z-index: 3;
}

.header__top {
  @extend %flex-ac;
  height: 60px;

  border-bottom: 1px solid #464749;

  @include breakpoint-min($tablet) {
    height: 72px;
  }
}
.header__bottom {
  @extend %flex-ac;
  height: 56px;

  @include breakpoint-max($desktopL - 1) {
    display: none;
  }
}

.header__container {
  // @extend %relative;

  @extend %flex-ac-jsb;
  // gap: 16px;
}

.header__btn-wrap {
  @extend %flex-ac;
  gap: 24px;

  @include breakpoint-max($tablet - 1) {
    gap: 16px;
  }
}

.header__btn {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;

  border-radius: 4px;

  @include breakpoint-max($tablet - 1) {
    display: none;
  }
}

.header__contact-link {
  @extend %flex-ac;
  gap: 16px;

  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  @extend %transition--100;

  &:hover {
    color: var(--color--accent, #9cfe01);
  }

  @include breakpoint-min($desktopL) {
    font-size: 16px;
    line-height: 24px
  }
}
